body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.inter{
  font-family: "Inter", sans-serif;
}
.p-poppins{
font-family: 'Poppins', sans-serif;
font-size: 14px;
}
.h3-poppins{
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  }
  