.service{
 display: flex;
 flex-direction: column;
}


@media only screen and (max-width: 1030px){
    .secsasad{
        display: none;
    }
}