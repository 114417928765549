.about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width:90%;
    align-items: center;
    ;
    margin: 50px auto;
    h2{
        font-size: 32px;
        margin: 0;
        color: white;
    }
    p{
        color: white;
        text-align: center;
        width: 80%;
        font-size: 18px;
        max-width: 970px;
        margin: 40px 0px 30px 0px ;
    }
    .slider-container{
        width: 100%;
        
        display: flex;
        justify-content: center;
        align-items: center;
        .gradient {
            background: lzinear-gradient(to right, #9a7db4, #6f4d70, #000);
            // animation: gradient-animation 2s linear infinite alternate;
            border-width: 10px;
            border-style: solid;
            border-image: linear-gradient(to right, #9a7db4, #6f4d70, #87658d);
            animation: gradient-animations 0.2s linear infinite alternate;
        }
        
        .gradient-2 {
            background: linear-gradient(to right, #b2a0cd,#87658d , #000);
            animation: gradient-animation 2s linear infinite alternate;

        }
        
        .gradient-3 {
            background: linear-gradient(to right, #d4c9e3, #73529d, #000);
            animation: gradient-animation 2s linear infinite alternate;

        }
        
        .gradient-4 {
            background: linear-gradient(to right, #b493c4, #745ca4, #000);
        }
        .gradient-5 {
            background: linear-gradient(to right, #d4c9e3, #73529d, #000);
        }
        
        .gradient-0 {
            background: linear-gradient(to right, #b493c4, #745ca4, #000);
        }
    
        .my-slide-component{
            display: flex;
            justify-content: center;
            width: 400px !important;
            align-items: center;
            text-align: center;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

@keyframes gradient-animation {
    0% {
      border-image-slice: 1;
    }
    100% {
      border-image-slice: 2;
    }
}
@keyframes gradient-animations {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 100% 0;
    }
  }