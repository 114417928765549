.about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 90%;
  align-items: center;
  margin: 50px auto;
}
.about h2 {
  font-size: 32px;
  margin: 0;
  color: white;
}
.about p {
  color: white;
  text-align: center;
  width: 80%;
  font-size: 18px;
  max-width: 970px;
  margin: 40px 0px 30px 0px;
}
.about .slider-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.about .slider-container .gradient {
  background: lzinear-gradient(to right, #9a7db4, #6f4d70, #000);
  border-width: 10px;
  border-style: solid;
  -o-border-image: linear-gradient(to right, #9a7db4, #6f4d70, #87658d);
     border-image: linear-gradient(to right, #9a7db4, #6f4d70, #87658d);
  animation: gradient-animations 0.2s linear infinite alternate;
}
.about .slider-container .gradient-2 {
  background: linear-gradient(to right, #b2a0cd, #87658d, #000);
  animation: gradient-animation 2s linear infinite alternate;
}
.about .slider-container .gradient-3 {
  background: linear-gradient(to right, #d4c9e3, #73529d, #000);
  animation: gradient-animation 2s linear infinite alternate;
}
.about .slider-container .gradient-4 {
  background: linear-gradient(to right, #b493c4, #745ca4, #000);
}
.about .slider-container .gradient-5 {
  background: linear-gradient(to right, #d4c9e3, #73529d, #000);
}
.about .slider-container .gradient-0 {
  background: linear-gradient(to right, #b493c4, #745ca4, #000);
}
.about .slider-container .my-slide-component {
  display: flex;
  justify-content: center;
  width: 400px !important;
  align-items: center;
  text-align: center;
}
.about .slider-container .my-slide-component img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

@keyframes gradient-animation {
  0% {
    border-image-slice: 1;
  }
  100% {
    border-image-slice: 2;
  }
}
@keyframes gradient-animations {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 0;
  }
}/*# sourceMappingURL=about.css.map */