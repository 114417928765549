.footer {
  display: flex;
  background-color: #1e0e2c;
  padding: 30px 0px 15px 0px;
  flex-direction: column;
}
.footer .container {
  display: flex;
  max-width: 90%;
  margin: 0 auto;
  justify-content: space-between;
}
.footer .container .figure {
  margin: 0;
  gap: 20px;
  font-size: 19px;
  display: flex;
  flex-direction: column;
  color: white;
}
.footer .container .figure h2 {
  font-size: 25px;
}
.footer .container .figure img {
  -o-object-fit: contain;
     object-fit: contain;
  max-width: 40px;
  height: 40px;
  width: 40px;
}
.footer .container .figure ul {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin: 0px 0px 0px 40px;
}
.footer .container nav {
  display: flex;
  gap: 40px;
}
.footer .container nav .ul {
  flex-direction: column;
}
.footer .container nav ul {
  display: flex;
  padding: 0;
  margin: 0;
  gap: 20px;
}
.footer .container nav ul li {
  list-style: none;
}
.footer .container nav ul li a {
  text-decoration: none;
  color: #fff;
  font-weight: 500;
  font-size: 1.2rem;
}

.snoop {
  display: flex;
  color: white;
  font-size: 16px;
  text-align: center;
}/*# sourceMappingURL=footer.css.map */