body{
  background-color: black;
}
.servicesde{
  display: none;
}
@media only screen and (max-width: 1030px) {
.servicesde{
  font-size: 38px;
  text-align: center;
  width: 100%;
  display: flex;
  color: white;
  padding-bottom: 50px;
}
}