.footer{
    display: flex;
    background-color: #1e0e2c;
    padding: 30px 0px 15px 0px;
    flex-direction: column;
.container{
    display: flex;
    max-width: 90%;
    margin: 0 auto;
    justify-content: space-between;
    // align-items: center;
    .figure{
        margin: 0;
        gap: 20px;
        font-size: 19px;
        display: flex;flex-direction: column;
        // align-items: center;
        color: white;
        h2{
            font-size: 25px;
        }
        img{
            object-fit: contain;
            max-width: 40px;
            height: 40px;
            width: 40px;
        }
        ul{
            display: flex;
            gap: 20px;
            justify-content:center;
            margin:  0px 0px 0px 40px;
        }
    }
    nav{
        display: flex;
        gap: 40px;
        .ul{
            flex-direction: column;
        }
        ul{
            display: flex;
            padding: 0;
            margin: 0;
            gap: 20px;
            // align-items: center;
            li{
                list-style: none;
                a{
                    text-decoration: none;
                    color: #fff;
                    font-weight: 500;
                    font-size: 1.2rem;
                   
                }
            }
        }
    }
}
}
.snoop{
    display: flex;
    color: white;
    font-size: 16px;
    text-align: center;
}